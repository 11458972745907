<template>
  <ConnectionsHistoryTableAdmin
      :countFilterParams="countFilterParams"
      :filterGetParams="filterGetParams"
      @changeTab="changeTab"
      @resetFilter="resetFilter"
      @changeFilter="changeFilter"
      @showMore="showMore"
      @reload="reload"
  />
</template>

<script>
  import ConnectionsHistoryTableAdmin from "./ConnectionsHistoryTableAdmin/ConnectionsHistoryTableAdmin";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";

  export default {
    name: "ConnectionsHistoryTable",

    components: {
      ConnectionsHistoryTableAdmin,
    },

    mixins: [routeFilter],

    computed: {
      router: function() {
        return this.$route.query
      }
    },

    watch: {
      loadUserAuthorizedData: function () {
        // set tab active and visible by permissions
        if(this.downloadPermissions){
          this.checkTabPermission()
        }
      },

      downloadPermissions() {
        this.checkTabPermission()
      }
    },

    data() {
      return {
        forPage: this.$store.getters.getConnectionsHistoryForPage,
        page: 1,
        onePage: 1,

        filterIp: '',
        filterCity: '',
        filterCountry: '',
        filterDate: [],

        filterUserId: '',
        filterUserName: '',

        countFilterParams: 0,
        filterGetParams: {},

      }
    },

    mounted() {
      if(this.loadUserAuthorizedData){
        // set tab active and visible by permissions
        if(this.downloadPermissions){
          this.checkTabPermission()
        }
      }
    },

    methods: {
      checkTabPermission() {

        if(!this.isAdmin) {
          return this.$router.push(this.$store.getters.GET_PATHS.notFound)
        }

        this.filter()
      },

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          type: this.$route.query.type,

          ip: this.$route.query.ip,
          city: this.$route.query.city,
          country: this.$route.query.country,
          date: this.$route.query.date,

          userId: this.$route.query.userId,
          userName: this.$route.query.userName,
        }
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
            generateMainFilter.page,
            generateMainFilter.forPage
        )

        this.$store.commit('setConnectionsHistoryFilter', window.location.search)

        if (next) this.$store.commit('setNextConnectionsHistoryPage', true)
        this.$store.dispatch('fetchConnectionsHistory', url).then(() => {
          this.$store.commit('setNextConnectionsHistoryPage', false)
        })

        this.checkCountFilter(['userName'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

        this.generateFilterQueryParams(
            myQuery,
            {
              filterUserId: 'user_id',
              filterIp: 'ip',
              filterCity: 'city',
              filterCountry: 'country',
            },
        )

        if (this.filterDate.length > 0) {
          let date = this.generateDateForFilter(this.filterDate, 'YYYY-MM-DD')

          myQuery.whereIn(
              'created_at',
              date
          )
        }

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

    }
  }
</script>

<style scoped>

</style>