<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter" @changeFilter="changeFilter">
    <template slot="body">

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['connectionsHistory_ip'])"></span>
        <DefaultInput
            :label="$t('connectionsHistory_ip.localization_value.value')"
            :type="'text'"
            v-model="ip"
        />
      </div>

      <div class="table-filter__item"
           v-if="isAdmin"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['common_user'])"></span>

        <SearchSelect
            :options="options"
            :label="$t('common_user.localization_value.value')"
            :functionSearch="functionSearch"
            @change="changeUsersFilter"
            :selected="userName"
            :userEmail="true"
            :typeSelect="'users'"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['connectionsHistory_city'])"></span>
        <DefaultInput
            :label="$t('connectionsHistory_city.localization_value.value')"
            :type="'text'"
            v-model="city"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['connectionsHistory_country'])"></span>

        <DefaultSelect
            :options="optionsCountries"
            :label="$t('connectionsHistory_country.localization_value.value')"
            :selected="getCountrySelected()"
            :optionsLabel="'label'"
            @change="changeCountry"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['connectionsHistory_timeOfVisit'])"></span>
        <DatePickerDefault
            :label="$t('connectionsHistory_timeOfVisit.localization_value.value')"
            v-model="date"
        >
          <template slot="body">
            <date-picker
                v-model="date"
                ref="datePicker"
                valueType="format"
                range
                :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                :format="'MM/DD/YY'"
                :placeholder="'mm/dd/yy'"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>


    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')" class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
import {usersFunc} from "../../../../../../../mixins/usersMixins/usersFunc";
import SearchSelect from "../../../../../../UI/selectiones/SearchSelect/SearchSelect";
import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
import {filterCountriesUSA} from "../../../../../../../services/filterCountries";
import DatePickerDefault from "../../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
import DatePicker from 'vue2-datepicker'

export default {
  name: "ConsolidationFilter",
  components: {
    DatePickerDefault,
    DatePicker,
    DefaultSelect,
    SearchSelect,
    FilterBlock,
    MainButton,
    DefaultInput,
  },

  props: {
    showFilter: Boolean,
    countFilterParams: Number,
    filterGetParams: Object,
  },

  mixins: [filterFunc, usersFunc],

  mounted() {
    this.$store.dispatch('fetchCountries').then(() => {
      let countries = this._.toArray(this.$store.getters.getCountries)
      filterCountriesUSA(countries)
      this.optionsCountries = countries;
      this.getCountrySelected()
    })
  },

  data(){
    return {
      ip: this.filterGetParams.ip ? this.filterGetParams.ip : '',
      city: this.filterGetParams.city ? this.filterGetParams.city : '',
      country: this.filterGetParams.country ? this.filterGetParams.country : '',

      userName: this.filterGetParams.userName ? this.filterGetParams.userName : '',
      userId: this.filterGetParams.userId ? this.filterGetParams.userId : '',

      date: this.filterGetParams.date ? this.generateFilterDate(this.filterGetParams.date, 'MM/DD/YY'): '',

      options: [],
      optionsCountries: [],

      filterCounts: [
        'ip',
        'city',
        'country',
        'date',
        'userName',
        'userId',
      ],
    }
  },

  watch: {
    filterGetParams(newVal) {
      this.ip = newVal.ip ? newVal.ip : ''
      this.city = newVal.city ? newVal.city : ''
      this.country = newVal.country ? newVal.country : ''
      this.date = newVal.date ? this.generateFilterDate(newVal.date, 'MM/DD/YY') : ''

      this.userId = newVal.userId ? newVal.userId : ''
      this.userName = newVal.userName ? newVal.userName : ''
    },

  },

  methods: {
    closeFilter() {
      this.$emit('close')
    },

    functionSearch(value, loading) {
      loading(true)
      this.getFilteredUsers(value, this, 'options', loading, true)
    },

    changeUsersFilter(value) {
      this.userId = value.id
      this.userName = value.contacts[0].user_full_name
    },

    resetFilter() {
      let data = this.resetFilterParams(this, this.filterCounts)

      this.$emit('changeFilter', data)
    },

    changeFilter() {
      let data = this.changeFilterParams(this, this.filterCounts)
      data.date = this.changeDateParams(this, 'date')

      this.$emit('changeFilter', data)
    },

    getCountrySelected(){
      if(this.country === '') return ''
      return this._.find(this.optionsCountries, {label: this.country})
    },

    changeCountry(val) {
      this.country = val.label
    },
  },
}
</script>

<style scoped>

</style>
